import { render, staticRenderFns } from "./AdminAccountsTable.vue?vue&type=template&id=bc65c21c&scoped=true&"
import script from "./AdminAccountsTable.vue?vue&type=script&lang=js&"
export * from "./AdminAccountsTable.vue?vue&type=script&lang=js&"
import style0 from "./AdminAccountsTable.vue?vue&type=style&index=0&id=bc65c21c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc65c21c",
  null
  
)

export default component.exports