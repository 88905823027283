<template>
  <v-container style="height: 100%">
    <v-card class="mt-4 pl-2" style="height: 100%">
      <v-row>
        <v-col cols="12"><h1>Пользователи</h1></v-col>
        <users-table />
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import UsersTable from "@/components/AdminAccountsTable";
import { CONSTS } from "../services/constants.js";

export default {
  mounted() {
    if (this.hasPriv) {
      this.$store.dispatch("GetUsers");
    } else {
      this.$router.push("/");
    }
  },
  components: {
    UsersTable,
  },
  data: () => ({}),
  computed: {
    hasPriv() {
      return !!this.$store.getters.StateUser.indicators[
        CONSTS.PRIVS_KEYS.section_accounts
      ];
    },
  },
  methods: {},
  watch: {
    "$route.path": function () {
      console.log(this.$route.name);
    },
  },
};
</script>
